import React, { useState, useEffect, useContext } from 'react';
import { faker } from '@faker-js/faker';
import Swal from 'sweetalert2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { UserContext } from '../context/UserContext'; // Importación corregida
import './Perfil.css';

const Perfil = () => {
    const { userData, setUserData } = useContext(UserContext);

    const [passwords, setPasswords] = useState({
        currentPassword: '',
        newPassword: '',
        confirmPassword: '',
    });

    const [showPasswords, setShowPasswords] = useState({
        currentPassword: false,
        newPassword: false,
        confirmPassword: false,
    });

    const [error, setError] = useState('');

    useEffect(() => {
        // Generate random user data
        setUserData({
            email: faker.internet.email(),
            name: faker.name.firstName(),
            lastname: faker.name.lastName(),
            second_surname: faker.name.lastName(),
            avatar: faker.image.avatar()
        });
    }, [setUserData]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setPasswords({
            ...passwords,
            [name]: value
        });
    };

    const toggleShowPassword = (field) => {
        setShowPasswords({
            ...showPasswords,
            [field]: !showPasswords[field]
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (passwords.newPassword !== passwords.confirmPassword) {
            setError('Las contraseñas no coinciden');
            return;
        }
        setError('');
        // Mostrar SweetAlert de confirmación
        Swal.fire({
            title: '¿Estás seguro de cambiar contraseña?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, cambiar contraseña'
        }).then((result) => {
            if (result.isConfirmed) {
                // Add logic to update password
                console.log('Password updated:', passwords);
                Swal.fire(
                    'Confirmado',
                    'Tu contraseña ha sido cambiada.',
                    'success'
                );
            }
        });
    };

    return (
        <div className="profile-container">
            <h1>Perfil de Usuario</h1>
            <div className="profile-info">
                <img src={userData.avatar} alt="Perfil" className="profile-img" />
                <p><strong>Nombre Completo:</strong> {userData.name} {userData.lastname} {userData.second_surname}</p>
                <p><strong>Correo Electrónico:</strong> {userData.email}</p>
            </div>
            <form onSubmit={handleSubmit} className="password-form">
                <label>
                    Contraseña Actual:
                    <div className="password-input-container">
                        <input 
                            type={showPasswords.currentPassword ? "text" : "password"} 
                            name="currentPassword" 
                            value={passwords.currentPassword} 
                            onChange={handleInputChange} 
                            required 
                        />
                        <FontAwesomeIcon 
                            icon={showPasswords.currentPassword ? faEyeSlash : faEye} 
                            onClick={() => toggleShowPassword('currentPassword')} 
                            className="toggle-password-icon"
                        />
                    </div>
                </label>
                <label>
                    Nueva Contraseña:
                    <div className="password-input-container">
                        <input 
                            type={showPasswords.newPassword ? "text" : "password"} 
                            name="newPassword" 
                            value={passwords.newPassword} 
                            onChange={handleInputChange} 
                            required 
                        />
                        <FontAwesomeIcon 
                            icon={showPasswords.newPassword ? faEyeSlash : faEye} 
                            onClick={() => toggleShowPassword('newPassword')} 
                            className="toggle-password-icon"
                        />
                    </div>
                </label>
                <label>
                    Confirmar Nueva Contraseña:
                    <div className="password-input-container">
                        <input 
                            type={showPasswords.confirmPassword ? "text" : "password"} 
                            name="confirmPassword" 
                            value={passwords.confirmPassword} 
                            onChange={handleInputChange} 
                            required 
                        />
                        <FontAwesomeIcon 
                            icon={showPasswords.confirmPassword ? faEyeSlash : faEye} 
                            onClick={() => toggleShowPassword('confirmPassword')} 
                            className="toggle-password-icon"
                        />
                    </div>
                </label>
                {error && <p className="error-message">{error}</p>}
                <button type="submit">Cambiar</button>
            </form>
        </div>
    );
};

export default Perfil;
