import React, { useState, useEffect } from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import { Card, CardContent, Typography, Box } from '@mui/material';
import 'leaflet/dist/leaflet.css';
import './Instalacion.css';

// Configuración del icono predeterminado
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon-2x.png',
  iconUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png',
  shadowUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png'
});

const center = [40.730610, -73.935242]; // Coordenadas de ejemplo

const Instalacion = () => {
  const [data, setData] = useState(null);

  useEffect(() => {
    // Llama a tu backend para obtener los datos
    fetch('/api/instalacion')
      .then(response => response.json())
      .then(data => {
        console.log(data); // Debugging output
        setData(data);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        // Usa datos ficticios en caso de error
        setData({
          capacidadSFV: 10,  // Capacidad del SFV en kWp
          marcaModulos: "SolarTech",  // Marca de los módulos fotovoltaicos
          imagenUrl: "https://www.gob.mx/cms/uploads/article/main_image/22469/FOTOVOLTAICO.jpg",  // URL de la imagen proporcionada
          ubicacion: "Queens, NY",  // Ubicación de la instalación
          temperatura: 38  // Temperatura en grados Celsius
        });
      });
  }, []);

  if (!data) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      {/* Título estático */}
      <h1>Instalación</h1>
      <MapContainer center={center} zoom={15} style={{ width: '100%', height: '400px' }}>
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        <Marker position={center}>
          <Popup>
            <div>
              <strong>Ubicación:</strong><br />
              {data.ubicacion}
            </div>
          </Popup>
        </Marker>
      </MapContainer>
      <Box display="flex" justifyContent="space-around" marginTop="20px">
        <Card sx={{ backgroundColor: '#4184A5', border: '1px solid #ccc' }}>
          <CardContent>
            <Typography variant="h5" component="div">
              Capacidad del SFV:
            </Typography>
            <Typography variant="body2">
              {data.capacidadSFV} kWp
            </Typography>
          </CardContent>
        </Card>
        <Card sx={{ backgroundColor: '#e7192CB', border: '1px solid #ccc' }}>
          <CardContent>
            <Typography variant="h5" component="div">
              Marca de módulos FV:
            </Typography>
            <Typography variant="body2">
              {data.marcaModulos}
            </Typography>
          </CardContent>
        </Card>
      </Box>
      <Box display="flex" justifyContent="center" marginTop="20px">
        <img src={data.imagenUrl} alt="Paneles Solares" className="imagen-paneles" />
      </Box>
      <Box display="flex" justifyContent="center" marginTop="10px">
        <Box className="ubicacion">
          <Typography variant="body1">{data.ubicacion}</Typography>
        </Box>
        <Box className="temperatura">
          <Typography variant="body1">{data.temperatura} °C</Typography>
        </Box>
      </Box>
    </div>
  );
};

export default Instalacion;
