import React, { useState, useEffect } from 'react';
import ReactPaginate from 'react-paginate';
import './Historial.css';

const Historial = () => {
  const [data, setData] = useState({
    estadoOperacion: 'Normal',
    potenciaMaxima: '3,45 kW',
    ultimoRegistro: '24/07/2024',
    temperaturaClima: '40.57°',
    historial: [
      { status: 'Inversor Normal', date: '2023-05-27 10:15 AM' },
      { status: 'Inversor Dañado', date: '2023-05-26 2:30 PM' },
      { status: 'Inversor Fallando', date: '2023-05-25 9:00 AM' },
      { status: 'Inversor Normal', date: '2023-05-27 10:15 AM' },
      { status: 'Inversor Dañado', date: '2023-05-26 2:30 PM' },
      { status: 'Inversor Fallando', date: '2023-05-25 9:00 AM' },
      { status: 'Inversor Normal', date: '2023-05-27 10:15 AM' },
      { status: 'Inversor Dañado', date: '2023-05-26 2:30 PM' },
      { status: 'Inversor Fallando', date: '2023-05-25 9:00 AM' },
    ],
  });
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 8;

  useEffect(() => {
    // Fetch data from backend and update the state
    // fetch('/api/your-endpoint')
    //   .then(response => response.json())
    //   .then(data => setData(data));
  }, []);

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
  };

  const pageCount = Math.ceil(data.historial.length / itemsPerPage);
  const displayHistorial = data.historial.slice(
    currentPage * itemsPerPage,
    (currentPage + 1) * itemsPerPage
  );
  const cards = [
    { title: 'Estado de Operación:', value: data.estadoOperacion, color: '#4184A5' },
    { title: 'Potencia CA máxima del día:', value: data.potenciaMaxima, color: '#7192CB' },
    { title: 'Fecha del último registro:', value: data.ultimoRegistro, color: '#6C8BB1' },
    { title: 'Temperatura (clima):', value: data.temperaturaClima, color: '#5F9B47' },
  ];

  return (
    <div className="historial-container">
      <div className="historial-content">
        <div className="historial-list">
          <h1>Historial</h1>
          {displayHistorial.map((item, index) => (
            <div key={index} className={`historial-item ${item.status.replace(' ', '-').toLowerCase()}`}>
              <span className="icon"></span>
              <div className="status">{item.status}</div>
              <div className="date">{item.date}</div>
            </div>
          ))}
          <ReactPaginate
            previousLabel={'<'}
            nextLabel={'>'}
            breakLabel={'...'}
            breakClassName={'break-me'}
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageClick}
            containerClassName={'pagination'}
            subContainerClassName={'pages pagination'}
            activeClassName={'active'}
          />
        </div>
        <div className="historial-cards">
          {cards.map((card, index) => (
            <div key={index} className="card" style={{ backgroundColor: card.color }}>
              <div className="card-title">{card.title}</div>
              <div className="card-value">{card.value}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Historial;
