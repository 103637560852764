// src/components/Sidebar.js
import React, { useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FaHome, FaMapMarkerAlt, FaChartBar, FaHistory, FaSignOutAlt } from 'react-icons/fa';
import { UserContext } from '../context/UserContext'; // Importa correctamente
import './Sidebar.css';

const Sidebar = () => {
    const { userData } = useContext(UserContext);
    const navigate = useNavigate();

    const handleLogout = () => {
        localStorage.removeItem('token');
        navigate('/login');
    };

    return (
        <div className="sidebar">
            <div className="sidebar-logo">
                <Link to="/perfil">
                    <img src={userData.avatar} alt="Logo person" className="profile-img" />
                </Link>
                <p className="profile-name">{userData.name} {userData.lastname}</p> {/* Nombre del usuario */}
            </div>
            <ul className="sidebar-menu">
                <li>
                    <Link to="/home">
                        <FaHome className="sidebar-icon" />
                        <span className="sidebar-text">Inicio</span>
                    </Link>
                </li>
                <li>
                    <Link to="/instalacion">
                        <FaMapMarkerAlt className="sidebar-icon" />
                        <span className="sidebar-text">Instalación</span>
                    </Link>
                </li>
                <li>
                    <Link to="/representacion">
                        <FaChartBar className="sidebar-icon" />
                        <span className="sidebar-text">Representación</span>
                    </Link>
                </li>
                <li>
                    <Link to="/historial">
                        <FaHistory className="sidebar-icon" />
                        <span className="sidebar-text">Historial</span>
                    </Link>
                </li>
            </ul>
            <div className="sidebar-footer">
                <button className="logout-button" onClick={handleLogout}>
                    <FaSignOutAlt className="sidebar-icon" />
                    <span className="sidebar-text">Cerrar sesión</span>
                </button>
            </div>
        </div>
    );
};

export default Sidebar;
