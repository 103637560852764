import React from 'react';
import './Card.css'; 
const Card = ({ investorNumber, date, status }) => {
  const statusColor = status === 'green' ? 'green' : status === 'yellow' ? 'yellow' : 'red';

  return (
    <div className="card">
      <p>Número de Inversor:</p>
      <h2>{investorNumber}</h2>
      <p>{date} (Actualizado Hace 16 horas)</p>
      <div className={`status ${statusColor}`}></div>
    </div>
  );
};

export default Card;