import React, { useState } from 'react';
import Card from './Card';
import './Home.css'; // Estilos para la nueva pantalla

const Home = () => {
    const [statuses, setStatuses] = useState(['green', 'red', 'yellow', 'green']);

    return (
        <div className="home-container">
            
                <h1>Inicio</h1>
                <div className="cards-container">
                    <Card investorNumber="PV201-CV1.101-I10T3-300424" date="05 de Junio. 2024" status={statuses[0]} />
                    <Card investorNumber="PV202-CV1.101-I10T3-300424" date="05 de Junio. 2024" status={statuses[1]} />
                    <Card investorNumber="PV203-CV1.101-I10T3-300424" date="05 de Junio. 2024" status={statuses[2]} />
                    <Card investorNumber="PV204-CV1.101-I10T3-300424" date="05 de Junio. 2024" status={statuses[3]} />
                </div>
        
        </div>
    );
};

export default Home;