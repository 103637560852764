// LoginForm.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './LoginForm.css';
import logo1 from '../assets/INEEL1.png';
import logo2 from '../assets/MAC1.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faLock, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

const LoginForm = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const navigate = useNavigate();

    const handleSubmit = (e) => {
        e.preventDefault();
        // Simulación de autenticación
        if (email === 'user@example.com' && password === 'password') {
            setError(false);
            const token = 'fake-jwt-token'; // Este debería ser el token real devuelto por tu backend
            localStorage.setItem('token', token); // Guardar el token en localStorage
            navigate('/home'); // Redirigir a la nueva pantalla de inicio
        } else {
            setError(true);
        }
    };

    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    return (
        <div className="login-form-container">
            <div className="login-form">
                <div className="logos">
                    <img src={logo1} alt="Logo INNEL" className="logo" />
                    <img src={logo2} alt="Logo MAC" className="logo" />
                </div>
                <h2>INICIAR SESIÓN</h2>
                {error && <div className="error">Correo electrónico o contraseña incorrectos</div>}
                <form onSubmit={handleSubmit}>
                    <div className="form-group input-group">
                        <div className="input-group-prepend">
                            <span className="input-group-text">
                                <FontAwesomeIcon icon={faEnvelope} />
                            </span>
                        </div>
                        <input 
                            type="email" 
                            id="email" 
                            className="form-control"
                            value={email} 
                            onChange={(e) => setEmail(e.target.value)} 
                            placeholder="Correo electrónico"
                            required 
                        />
                    </div>
                    <div className="form-group input-group">
                        <div className="input-group-prepend">
                            <span className="input-group-text">
                                <FontAwesomeIcon icon={faLock} />
                            </span>
                        </div>
                        <input 
                            type={showPassword ? "text" : "password"} 
                            id="password" 
                            className="form-control"
                            value={password} 
                            onChange={(e) => setPassword(e.target.value)} 
                            placeholder="Contraseña"
                            required 
                        />
                        <div className="input-group-append">
                            <span className="input-group-text" onClick={toggleShowPassword} style={{ cursor: 'pointer' }}>
                                <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                            </span>
                        </div>
                    </div>
                    <button type="submit" className="btn btn-primary btn-block">INICIAR SESIÓN</button>
                </form>
            </div>
            <div className="login-image">
                <img src="/fondo.png" alt="Background" />
            </div>
        </div>
    );
};

export default LoginForm;
