import React from 'react';
import INEEL1 from '../assets/INEEL1.png'; 
import MAC1 from '../assets/MAC1.png';
import './Footer.css'; 

const Footer = () => {
  return (
    <footer className="footer">
    <div className="footer-content">
      <img src={INEEL1} alt="INEEL Logo" className="footer-logo" />
      <img src={MAC1} alt="MAC Logo" className="footer-logo" />
    </div>
    <p>© Copyright 2023 - MAC Manufacturing</p>
  </footer>
  );
};

export default Footer;