import React, { useState, useEffect } from 'react';
import { Line, Bar } from 'react-chartjs-2';
import axios from 'axios';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import 'moment/locale/es';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import './Representacion.css'; // Importa el archivo CSS
import zoomPlugin from 'chartjs-plugin-zoom';


moment.locale('es');

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  zoomPlugin
);

const Representacion = () => {
  const [selectedChart, setSelectedChart] = useState('Voltaje CD');
  const [chartData, setChartData] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [consulta, setConsulta] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchConsulta = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get('http://localhost:8080/api/monitoreo/');
        setConsulta(response.data.data);
        setIsLoading(false);
        console.log('Consulta:', response.data.data); // Imprime los datos en la consola
      } catch (error) {
        console.error('Error fetching consulta:', error);
        setIsLoading(false);
      }
    };

    fetchConsulta();
  }, []);

  const handleGraficar = () => {
    if (selectedChart !== 'Energía Eléctrica') {
      fetchData();
    }
  };

  const fetchData = () => {
    if (!consulta) return;
    setIsLoading(true);
    const start = moment(startDate).startOf('day');
    const end = moment(endDate).endOf('day');
    const filteredData = consulta.filter((point) => {
      const pointDate = moment(point.fecha);
      return pointDate.isBetween(start, end, null, '[]');
    });
    setChartData(filteredData);
    setIsLoading(false);
  };

  const chartTypes = [
    { label: 'Voltaje CD', key: 'vin' },
    { label: 'Corriente CD', key: 'iin' },
    { label: 'Potencia CD', key: 'potin' },
    { label: 'Voltaje bus CD', key: 'vbus' },
    { label: 'Ciclo de trabajo', key: 'dcy' },
    { label: 'Voltaje red eléctrica', key: 'vout' },
    { label: 'Corriente CA', key: 'iout' },
    { label: 'Frecuencia de red', key: 'frec' },
    { label: 'Potencia CA', key: 'poten' },
  ];

  const selectedChartKey = chartTypes.find(chart => chart.label === selectedChart)?.key;

  const dataLine = {
    labels: chartData.map((point) => moment(point.fecha).format('DD/MM/YYYY HH:mm:ss')),
    datasets: [
      {
        label: selectedChart,
        data: chartData.map((point) => point[selectedChartKey]),
        fill: false,
        borderColor: 'blue',
      },
    ],
  };

  const optionsLine = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: selectedChart,
      },
      zoom: {
        zoom: {
          wheel: {
            enabled: true,
          },
          pinch: {
            enabled: true,
          },
          mode: 'x',
        },
        pan: {
          enabled: true,
          mode: 'x',
        },
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'Horario',
        },
        // Puedes ajustar otros aspectos aquí
      },
      y: {
        title: {
          display: true,
          text: selectedChart,
        },
      },
    },
  };
  
  

  const dataBar = {
    labels: chartData.map((point) => moment(point.fecha).format('DD/MM/YYYY HH:mm:ss')),
    datasets: [
      {
        label: 'Energía Eléctrica',
        data: chartData.map((point) => point.energy),
        backgroundColor: 'rgba(75, 192, 192, 0.5)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1,
      },
    ],
  };

  const optionsBar = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Energía Eléctrica',
      },
      zoom: {
        zoom: {
          wheel: {
            enabled: true,
          },
          pinch: {
            enabled: true,
          },
          mode: 'x',
        },
        pan: {
          enabled: true,
          mode: 'x',
        },
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'Fecha',
        },
        ticks: {
          autoSkip: true,
          maxTicksLimit: 10,
        },
      },
      y: {
        title: {
          display: true,
          text: 'Valor',
        },
        beginAtZero: true,
      },
    },
  };
  

  return (
    <div className="container">
      <h1>Representación de Datos</h1>
      <div className="controls">
        <div className="controlItem">
          <label>Fecha Inicio: </label>
          <DatePicker
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            dateFormat="dd/MM/yyyy"
            locale="es"
          />
        </div>
        <div className="controlItem">
          <label>Fecha Final: </label>
          <DatePicker
            selected={endDate}
            onChange={(date) => setEndDate(date)}
            dateFormat="dd/MM/yyyy"
            locale="es"
          />
        </div>
        <div className="controlItem graficarButton">
          <button onClick={handleGraficar}>Graficar</button>
        </div>
        <div className="controlItem">
          <label>Tipo de Dato: </label>
          <select onChange={(e) => setSelectedChart(e.target.value)} value={selectedChart}>
            {chartTypes.map((type) => (
              <option key={type.label} value={type.label}>
                {type.label}
              </option>
            ))}
          </select>
        </div>
      </div>
      {isLoading ? (
        <div className="loading">Cargando datos...</div>
      ) : (
        <>
          <div className="chartContainer">
            <Line data={dataLine} options={optionsLine} />
          </div>
          <div className="chartContainer">
            <h2>Energía Eléctrica</h2>
            <Bar data={dataBar} options={optionsBar} />
          </div>
        </>
      )}
    </div>
  );
};

export default Representacion;
